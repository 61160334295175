import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import MainLayOut from "./component/MainLayOut";
import Home from "./pages/Home";
import { useEffect } from "react";
import Login from "./pages/login/Login";
import { useDispatch, useSelector } from "react-redux";
import AddAdmin from "./pages/admin-magangement/AddEmployee";
import ListAdmin from "./pages/admin-magangement/EmployeeList";
import SubmittedCase from "./pages/cases/SubmittedCase";
import AssignedCase from "./pages/cases/AssignedCase";
import PendingCase from "./pages/cases/PendingCase";
import { getAllEmployee } from "./redux/Employee/employeeSlice";
import AddEmployee from "./pages/admin-magangement/AddEmployee";
import EmployeeList from "./pages/admin-magangement/EmployeeList";
import UpdateEmployee from "./pages/admin-magangement/UpdateEmployee";
import AddClient from "./pages/admin-magangement/AddClient";
import BackendList from "./pages/cases/BackendList";
import CreateCase from "./pages/cases/CreateCase";
import ReleaseReport from "./pages/Reports/ReleaseReport";
import UpdateCase from "./pages/cases/UpdateCase";
import BrandLogo from "./pages/Logo/BrandLogo";
import OrganisationLogo from "./pages/Logo/OrganisationLogo";
import { getLogo } from "./redux/Logo/BrandLogoSlice";
import { getOrgLogo } from "./redux/Logo/OrgLogoSlice";
import ClientList from "./pages/admin-magangement/ClientList";
import Notification from "./pages/notification/Notification";
import { getAllNotification } from "./redux/Notifications/NotificationSlice";
import PrivecyPolicy from "./pages/PrivecyPolicy";
import NewReleaseReport from "./pages/Reports/NewReleaseReport";
import TwoWheelerReport from "./pages/Reports/TwoWheelerReport";
import ConstructionReport from "./pages/Reports/ConstructionReport";
import CommercialReport from "./pages/Reports/CommercialReport";
import ReadyReport from "./pages/cases/ReadyReport";
import ThreeWheelerReport from "./pages/Reports/ThreeWheelerReport";
import FarmEquipmentReport from "./pages/Reports/FarmEquipmentReport";
import ImageReport from "./pages/Reports/ImageReport";
import ClientLogin from "./pages/login/ClientLogin";
import ClientPage from "./pages/Client/ClientPage";
import ClientDashboard from "./pages/Client/ClientDashboard";
import { getAllCases } from "./redux/Cases/CaseSlice";
import { getCarModels } from "./redux/ClientAuthentication/ClientSlice";

function ScrollToTop() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const { loginData, isAuth } = useSelector((store) => store.clientAuth);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const initialSetup = async () => {
      try {
        dispatch(getAllCases(loginData._id));
        dispatch(getAllEmployee());
        dispatch(getLogo());
        dispatch(getOrgLogo());
        dispatch(getCarModels());
        dispatch(getAllNotification());
      } catch (error) {}
    };
    initialSetup();
  }, []);

  return null;
}

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<ClientLogin />} />
          <Route path="/client" exact element={<MainLayOut />}>
            <Route index element={<Home />} />
            <Route path="/client/readyreport" element={<ReadyReport />} />
            <Route path="/client/assignedcases" element={<AssignedCase />} />
            <Route path="/client/createcases" element={<CreateCase />} />
          </Route>
          <Route path="/releasereport/:caseId" element={<NewReleaseReport />} />
          <Route
            path="/twowheelerreportrelease/:caseId"
            element={<TwoWheelerReport />}
          />
          <Route
            path="/constructionreportrelease/:caseId"
            element={<ConstructionReport />}
          />
          <Route
            path="/commercialreportrelease/:caseId"
            element={<CommercialReport />}
          />
          <Route
            path="/threewheelerreportrelease/:caseId"
            element={<ThreeWheelerReport />}
          />
          <Route
            path="/firmequipmentreportrelease/:caseId"
            element={<FarmEquipmentReport />}
          />
          <Route path="/imagereport/:caseId" element={<ImageReport />} />
          <Route path="/privecy-policy" element={<PrivecyPolicy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
