import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import moment from "moment-timezone";

const AssignedCase = () => {
    const { caseCreated } = useSelector((store) => store.cases);
    const [search, setSearch] = useState("");
    const [filterData, setFilterData] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // useEffect(() => {
    //     // Function to refresh the page
    //     const refreshPage = () => {
    //       window.location.reload();
    //     };

    //     // Call the refreshPage function when the component mounts
    //     refreshPage();
    //   }, []);

    console.log(caseCreated.length, 'caseCreated');
    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                <div className="p-6 space-y-6">
                    <div className="flex items-center justify-between w-full print:hidden">
                        <h4 className="text-lg font-semibold text-default-900">
                            Dashboard
                        </h4>
                    </div>
                </div>
                <div className="grid grid-cols-1">
                    <div className="shadow rounded-lg bg-white dark:bg-default-50">

                        <div className="border-t border-dashed border-default-200">
                            <div className="relative overflow-x-auto">
                                <table className="min-w-full overflow-x-hidden">
                                    <thead className="border-b border-dashed border-default-200">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                                            >
                                                No
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-40"
                                            >
                                                Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Mobile
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Date
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Reg No.
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Email
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                            >
                                                Status
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-dashed divide-default-200">
                                        {caseCreated &&
                                            caseCreated
                                                .map((admin, index) => (
                                                    <tr key={index}>
                                                        <td className="px-6 py-3 text-center text-default-600 font-semibold whitespace-nowrap">
                                                            <b>{index + 1}</b>
                                                        </td>
                                                        <td className="px-6 py-3 text-center whitespace-nowrap">
                                                            <h6 className="text-sm font-semibold text-default-700">
                                                                {admin.name.length > 30 ? (
                                                                    <>{admin.name.slice(0, 30)}...</>
                                                                ) : (
                                                                    <>{admin.name.slice(0, 30)}</>
                                                                )}
                                                            </h6>
                                                        </td>
                                                        <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap">
                                                            {admin.mobile}
                                                        </td>
                                                        <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap">
                                                            {moment(admin.createdAt)
                                                                .tz("Asia/Kolkata")
                                                                .format("YYYY-MM-DD HH:mm:ss")}
                                                        </td>
                                                        <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap">
                                                            {admin.regdNo}
                                                        </td>
                                                        <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap">
                                                            {admin.email.length > 18 ? (
                                                                <>{admin.email.slice(0, 18)}...</>
                                                            ) : (
                                                                <>{admin.email.slice(0, 18)}</>
                                                            )}
                                                        </td>
                                                        <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap">
                                                            {admin.statusText}
                                                        </td>
                                                    </tr>
                                                ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="flex flex-wrap items-center justify-between gap-6 py-3 px-6 border-t border-dashed border-default-200">
                            <h6 className="text-default-600">Showing {caseCreated.length} results</h6>
                            {/* <nav className="flex items-center gap-1">
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    <i className="ph ph-caret-left text-base" />
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border rounded-md transition-all duration-200 bg-primary text-white border-primary"
                                    to="#"
                                    aria-current="page"
                                >
                                    1
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    2
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    ...
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    12
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    <i className="ph ph-caret-right text-base" />
                                </a>
                            </nav> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AssignedCase