// import React from 'react'

// const ClientLogin = () => {
//   return (
//     <div>ClientLogin</div>
//   )
// }

// export default ClientLogin


import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";
import { IoChevronUp, IoMoonOutline } from "react-icons/io5";
import { GoSun } from "react-icons/go";
import { Link } from "react-router-dom";
import { clientActions, employeeLoginBypassword } from "../../redux/ClientAuthentication/ClientSlice";

const ClientLogin = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errormessage, setErrormessage] = useState("");
    const [errormsgClr, setErrormsgClr] = useState("red");
    const [passwordType, setPasswordType] = useState("password");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(()=>{
        // window.location.reload()
    },[])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrormessage("Validating email or password..");
        setErrormsgClr("green");
        if (email !== "" && password !== "") {
            const formData = {
                email: email,
                password: password,
            };
            const loginResponse = await dispatch(employeeLoginBypassword(formData));
            if (loginResponse.payload.success) {
                setErrormsgClr("green");
                setErrormessage("Validated your account");
                navigate("/client");
                setEmail("");
                setPassword("");
                setErrormessage("");
                setErrormsgClr("");
            } else {
                setErrormessage("Invalid phone or password");
                setErrormsgClr("red");
            }
        }
    };

    return (
        <>
            <div className="relative lg:h-screen py-6 flex justify-center items-center bg-auto bg-no-repeat bg-primary/10">
                <div className="container">
                    <div className="flex justify-center">
                        <div className="lg:w-1/3 md:w-2/3 w-full relative">
                            <div className="relative bg-white rounded-lg shadow-lg p-6 z-10 dark:bg-default-50">
                                <h4 className="text-2xl font-semibold text-default-800 mb-4" style={{ textAlign: 'center' }}>
                                    Client Log In
                                </h4>
                                <form 
                                    data-x-form
                                    data-x-form-to="home.html"
                                    className="mt-10"
                                    onSubmit={(e) => handleSubmit(e)}
                                >
                                    <div className="mb-4">
                                        <label
                                            htmlFor="LogInEmailAddress"
                                            className="block text-base/normal font-semibold text-default-900 mb-2"
                                        >
                                            Email
                                        </label>
                                        <input
                                            type="text"
                                            data-x-field="email"
                                            className="block w-full rounded py-2.5 px-4 bg-transparent border-default-200 text-default-900 focus:outline-0 focus:ring-0"
                                            id="LogInEmailAddress"
                                            placeholder="Enter your email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <span
                                            data-x-field-error="email"
                                            className="text-red-500 mt-1 block"
                                        />
                                    </div>
                                    {/* end email input */}
                                    <div className="mb-4">
                                        <label
                                            htmlFor="password"
                                            className="block text-base/normal font-semibold text-default-900 mb-2"
                                        >
                                            Password
                                        </label>
                                        <div className="flex" data-x-password>
                                            <input
                                                data-x-field={passwordType}
                                                defaultValue={passwordType}
                                                type={passwordType}
                                                id="form-password"
                                                className="form-password text-default-900 block w-full rounded-s py-2.5 px-4 bg-transparent border border-default-200 focus:ring-transparent -me-px"
                                                placeholder="Enter your password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                            {passwordType === "password" ? (
                                                <>
                                                    <button
                                                        type="button"
                                                        className="password-toggle inline-flex items-center justify-center py-2.5 px-4 border rounded-e bg-transparent border-default-200"
                                                        onClick={() => setPasswordType("text")}
                                                    >
                                                        <FaEyeSlash className="password-eye-on h-5 w-5 text-default-600" />
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <button
                                                        type="button"
                                                        className="password-toggle inline-flex items-center justify-center py-2.5 px-4 border rounded-e bg-transparent border-default-200"
                                                        onClick={() => setPasswordType("password")}
                                                    >
                                                        <FaEye className="password-eye-off h-5 w-5 text-default-600" />
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                        <span
                                            data-x-field-error="password"
                                            className="text-red-500 mt-1 block"
                                        />
                                    </div>
                                    {/* end password input */}
                                    <div className="flex justify-between items-center flex-wrap gap-x-1 gap-y-2 mb-6">
                                        <span
                                            style={{
                                                cursor: "pointer",
                                                display: "none",
                                            }}
                                            className="text-default-900 border-b border-dashed"
                                        >
                                            <small>Forgot your password?</small>
                                        </span>
                                    </div>
                                    {/* end checkbox input */}
                                    <div className="mb-6 text-start">
                                        {errormessage.length > 0 ? (
                                            <>
                                                <div className="mt-2 mb-2">
                                                    <p style={{ color: errormsgClr }}>{errormessage}</p>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}

                                        <button
                                            type="submit"
                                            className="relative inline-flex items-center justify-center px-6 py-3 rounded text-base bg-primary text-white capitalize transition-all hover:bg-primary-500 w-full"
                                        >
                                            Log In
                                        </button>
                                    </div>
                                </form>
                            </div>
                            {/* <div className="lg:flex hidden">
                <div className="absolute start-0 bottom-0 -ms-[11.75rem] -z-0">
                  <img src="assets/auth-cardbg-1-5e4948cf.png" alt className />
                </div>
                <div className="absolute end-0 bottom-0 -me-36 -z-0">
                  <img src="assets/auth-cardbg-2-8584e00f.png" alt className />
                </div>
              </div> */}
                        </div>
                    </div>
                </div>
                <div className="fixed bottom-5 flex flex-col items-center ">
                    <Link
                        to="/privecy-policy"
                        className="  flex justify-center items-center z-20"
                        style={{ color: "#1a1a1a", padding: 5, fontWeight: "600" }}
                    >
                        Privacy Policy
                    </Link>
                </div>
            </div>
        </>
    );
};

export default ClientLogin;
